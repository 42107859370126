<template>
  <el-upload
    name="fileInput"
    :headers="{
      Authorization: token ? 'Bearer ' + token : ''
    }"
    :limit="1"
    show-file-list
    :action="uploadUrl"
    accept=".xls,.xlsx"
    :on-progress="onUploadProgress"
    :on-error="onUploadError"
    :on-success="onUploadSuccess"
    :on-exceed="onUploadExceed"
    :on-remove="onUploadRemove">
    <el-button type="primary" @click="onDownloadTpl">{{ map[table] }}(参考模版)</el-button>
    <el-button>上传<i class="el-icon-upload el-icon--right"></i></el-button>
    <p class="upload-tip">1.点击上传文件，支持xsl，xlsx格式</p>
    <p class="upload-tip">2.上传后系统自动解析匹配字段</p>
    <p class="upload-tip">3.为提高效率建议使用模版(可选)</p>
    <p class="upload-tip"><b>提示:保存前请认真检查，入库列明和上传列名的映射对照关系！</b></p>
  </el-upload>
</template>

<script>
import * as commonApi from '@/api/common'
import setting from '@/setting'
import { mapState } from 'vuex'

export default {
  props: {
    table: {
      type: String,
      default: '',
      require: true
    }
  },
  data() {
    return {
      loading: false,
      fileList: [],
      map: {
        D_Sample: '基本信息',
        D_ICPMS: '定年分析',
        D_otopes: '同位素分析',
        D_Micronutrient: '主量元素分析',
        D_Principal_Element: '微量元素分析'
      }
    }
  },
  computed: {
    ...mapState(['user', 'token']),
    uploadUrl() {
      return `${setting.baseUrl.api}D_Sample/Import_getLM?tablename=${this.table}`
    }
  },
  // emits: ['uploadSuccess'],
  methods: {
    onDownloadTpl() {
      let url = location.origin + `/static/自定义导入/${this.map[this.table]}.xlsx`
      window.open(url, '_blank')
    },
    onUploadProgress() {
      this.loading = true
    },
    onUploadError(err) {
      this.loading = false
    },
    onUploadSuccess(res, file) {
      this.loading = false
      let { status, data, xlszd,message} = res
      
      this.$message.success({
          message: res.message
        })


     
      if (status) {
        this.$message.success({
          message: '上传成功！'
        })
        this.$emit('uploadSuccess', {
          data,
          xlszd
        })
      }
      else
      {
        this.$message.success({
                message: '需要登录'
        })
      }
    },
    onUploadExceed() {
      this.$message.error({
        message: '如需重新上传，请删除已上传文件！'
      })
    },
    onUploadRemove() {}
  },
  mounted() {
    
  }
}
</script>

<style lang="less" scoped>
.upload {
  &-tip {
    margin-top: 10px;
    color: #666;
  }
}
</style>
