/**基础信息 */
import request from '@/utils/request'

export function getPageData(data) {
  // 过滤掉实验数据 1：项目数据 2：实验数据 3：期刊数据
  data.wheres = data.wheres ? JSON.parse(data.wheres) : [];
  data.wheres.push({name:"DataType","value":"1,3",displayType:"selectList"})
  data.wheres = JSON.stringify(data.wheres)
  return request({
    url: '/D_Sample/GetPageData',
    method: 'post',
    data
  })
}

// 添加
export function add(data) {
  return request({
    url: '/D_Sample/Add',
    method: 'post',
    data
  })
}

// 文献查重
export function wxCheckIsRepeat(params) {
  return request({
    url: '/D_Sample/add_check_count',
    method: 'get',
    params
  })
}

// 添加-自定义
export function addByZdy(data) {
  return request({
    url: '/D_Sample/import_zdy',
    method: 'post',
    data
  })
}

// 自定义数据-获取匹配度
export function zdyDataGetPPD(params) {
  return request({
    url: '/D_Sample/Import_getLMppd',
    method: 'post',
    params
  })
}

// 自定义数据-质检修改
export function zdyDataCheckUpdate(params) {
  return request({
    url: '/D_Sample/import_zdy_update',
    method: 'get',
    params
  })
}

// 删除
export function Del(ids) {
  return request({
    url: '/D_Sample/del',
    method: 'post',
    data: ids
  })
}


export function exportData(data) {
  return request({
    url: '/D_Sample/Export',
    method: 'post',
    data
  })
}

export function downloadFile(fileCode) {
  return request({
    url: `/D_Sample/DownLoadFile?path=${fileCode}`,
    responseType: 'blob'
  })
}

export default {
  getPageData,
  Del,
  exportData,
  downloadFile
}
