import request from '@/utils/request'


// 获取表信息
export function LoadTableInfo(tableId) {
  return request({
    url: `/builder/LoadTableInfo?table_Id=${tableId}&isTreeLoad=true`,
    method: 'post'
  })
}
// 获取字典
export function getVueDictionary(dictCodeArr) {
  return request({
    url: '/Sys_Dictionary/GetVueDictionary',
    method: 'post',
    data: dictCodeArr
  })
}
// 获取字典详情
export function getDictDetail(code) {
  return request({
    url: '/Sys_Dictionary/getDetailPage',
    method: 'post',
    data: {
      page: 1,
      rows: 100,
      sort: "DicList_ID",
      value: code,
      wheres: "[]",
      order: "desc"
    }
  })
}

// 发送邮件
export function sendEmail(data) {
  let domap = {
    reg: '用户注册验证',
    findpwd: '密码找回验证'
  }
  return request({
    url: '/ApiAdmin/mailCheck',
    method: 'post',
    params: {
      ReceiveEmail: data.email,
      CheckType: domap[data.do]
    }
  })
}

/**
 * 获取轮播和图标介绍
 * @param {*} type banner:轮播 intro: 图标简介
 * @returns 
 */
export function getBannerAndIntro(type='banner') {
  let wheres = [
    {
      name: 'AdType',
      value: type === 'banner' ? 'BannerTop1' : 'BannerTop2',
      displayType: 'select'
    }
  ]
  return request({
    url: '/Home_AD/getPageData',
    method: 'post',
    data: {
      page: 1,
      rows: type === 'banner' ? 10 : 3,
      sort: 'OrderNo',
      order: 'desc',
      wheres: JSON.stringify(wheres)
    }
  })
}

// 访客统计
export function getVisitData(topNum=5) {
  return request({
    url: '/Data_STATS/getPageData',
    method: 'post',
    data: {
      page: 1,
      rows: topNum,
      sort: "CRonCount",
      order: "desc",
      wheres: JSON.stringify([{ name: 'NameType', value: '访问统计' }])
    }
  })
}

// 数据统计
export function getStatisticsData() {
  return request({
    url: '/Data_STATS/getPageData',
    method: 'post',
    data: {
      page: 1,
      rows: 9999999,
      // wheres: JSON.stringify([{name: 'NameType', value: '预约统计', displayType: 'select'}])
    }
  })
}

export default {
  getVueDictionary,
  getDictDetail
}
