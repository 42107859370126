<template>
  <el-select
    v-bind="$attrs"
    clearable
    @change="$emit('input', $event)">
    <el-option
      v-for="item in options"
      :key="item.key"
      :label="item.value"
      :value="item.key">
    </el-option>
  </el-select>
</template>

<script>
import * as commonApi from '@/api/common'
export default {
  props: {
    code: {
      type: String,
      default: '',
      require: true
    }
  },
  data() {
    return {
      options: []
    }
  },
  emits: ['input'],
  methods: {
    async loadData() {
      const dicts = await commonApi.getVueDictionary([this.code])
      if (dicts && dicts.length) {
        this.options = dicts.find(item => item.dicNo === this.code).data
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.wrap {
  height: 500px;
  .upload-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.col-map {
  height: 500px;
  overflow: auto;
  &-head {
    display: flex;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
    .head-item {
      padding: 0 15px;
      width: 200px;
      border: 1px solid #DCDFE6;
      border-right: none;
      line-height: 38px;
      font-size: 14px;
      text-align: center;
      background-color: rgba(128, 134, 149, 0.05);
      &:last-child {
        border-right: 1px solid #DCDFE6;
      }
    }
  }
  &-item {
    display: flex;
    margin-bottom: 15px;
    .item {
      padding: 0 15px;
      width: 200px;
      line-height: 38px;
      font-size: 14px;
      text-align: center;
      &--table-col {
        border: 1px solid #DCDFE6;
        border-right: none;
        background-color: rgba(128, 134, 149, 0.05);
      }
      &--xls-col {
        padding: 0;
      }
    }
  }
}
</style>
