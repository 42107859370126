<template>
  <div class="wrap">
    <div v-show="step === 'upload'" class="upload-wrap">
      <UploadExcel
        :table="table"
        @uploadSuccess="onUploadSuccess"
      />
    </div>
    <div v-show="step === 'col-map'" class="col-map">
      <div v-if="lastData?.length" class="actions">
        <el-button
          v-if="!isUseLastColMap"
          type="primary"
          @click="useLastColMap">
          使用上次列映射
        </el-button>
        <el-button
          v-else
          type="primary"
          @click="unUseLastColMap">
          不使用上次列映射
        </el-button>
      </div>
      <div class="col-map-head">
        <div class="head-item">上传列表<i class="el-icon-edit"></i></div>
        <div class="head-itembz">标准列名</div>
        <div class="head-itembz">匹配度</div>
        <div class="head-itembz">数据类型</div>
        <!-- <div class="head-item">参考值</div> -->
      </div>
      <div
        class="col-map-item"
        v-for="item in data"
        :key="item.columnId">
       
        <div class="item item--xls-col">
          <el-select
            v-model="item.xls_yszd"
            placeholder="请选择"
            @change="getPPD(item)">
            <el-option
              v-for="val in xlszd"
              :key="val"
              :label="val"
              :value="val">
            </el-option>
          </el-select>
          <i class="el-icon-right"></i>
        </div>
        <div class="item item--table-col">{{ item.columnCNName }}</div>
        <div class="item">{{ item.xls_ppd ?? '-' }}%</div>
        <div class="item">{{ item.columnType ?? '-' }}</div>

        <!-- <div class="item item--table-col">

          <div  v-for="(value, key) in item.keyValues" :key="key">
            {{ value }}
        </div>
      </div> -->

      </div>
    </div>
  </div>
</template>

<script>
import * as dSampleApi from '@/api/dSample'
import UploadExcel from './UploadExcel.vue'
import { last } from 'lodash';
export default {
  props: {
    table: {
      type: String,
      default: '',
      require: true
    }
  },
  data() {
    return {
      loading: false,
      step: 'upload', // upload col-map
      data: [],
      xlszd: [],
      dataRaw: [], // 本次上传成功后源数据
      isUseLastColMap: false,
      lastData: [] // 上次提交数据
    }
  },
  components: {
    UploadExcel
  },
  methods: {
    onUploadSuccess({ data, xlszd}) {
      this.data = data
      this.dataRaw = JSON.parse(JSON.stringify(data))
      this.xlszd = xlszd
      this.step = 'col-map'
      this.lastColMapInit()
    },
    // 上次列映射初始化
    lastColMapInit() {
      let cache = localStorage.getItem('nonStandardLastSubmitData')
      if (cache) {
        this.lastData = JSON.parse(cache)[this.table] ?? []
      }
    },
    // 使用上次缓存映射
    useLastColMap() {
      this.data.forEach(item => {
        let model = this.lastData.find(lastItem => lastItem.columnCNName === item.columnCNName)
        if (model) {
          item.xls_yszd = model.xls_yszd
        }
      })
      this.isUseLastColMap = true
    },
    // 不使用上次缓存映射
    unUseLastColMap() {
      this.data.forEach(item => {
        let model = this.dataRaw.find(lastItem => lastItem.columnCNName === item.columnCNName)
        if (model) {
          item.xls_yszd = model.xls_yszd
        }
      })
      this.isUseLastColMap = false
    },
    async getPPD(item) {
      try {
        const { data, status } = await dSampleApi.zdyDataGetPPD({
          zdname: item.columnCNName,
          zdnamexls: item.xls_yszd
        })
        if (status) {
          item.xls_ppd = data.xls_ppd
        }
      } catch (error) {
        
      }
    },
    getFormData() {
      let data = JSON.parse(JSON.stringify(this.data))
      console.log('data:', data)
      return data
    }
  },
  mounted() {
    // this.LoadTableInfo()
  }
}
</script>

<style lang="less" scoped>
.wrap {
  height: 500px;
  .upload-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.col-map {
  height: 500px;
  overflow: auto;
  .actions {
    margin-bottom: 15px;
  }
  &-head {
    display: flex;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 16px;
    .head-item {
      padding: 0 15px;
      width: 25%;
      border: 1px solid #DCDFE6;
      border-right: none;
      line-height: 38px;
      font-size: 14px;
      text-align: center;
      background-color: rgba(128, 134, 149, 0.05);
      &:last-child {
        border-right: 1px solid #DCDFE6;
      }
    }

    .head-itembz {
      padding: 0 15px;
      width: 25%;
      border: 1px solid #DCDFE6;
      border-right: none;
      line-height: 38px;
      font-size: 14px;
      text-align: center;
      background-color: #eee;
      &:last-child {
        border-right: 1px solid #DCDFE6;
      }
      
    }
  }
  &-item {
    display: flex;
    margin-bottom: 0px;
    .item {
      padding: 0 15px;
      width: 25%;
      line-height: 38px;
      font-size: 14px;
      text-align: center;
      border: 1px solid #DCDFE6;
      border-top: none;
      border-right: none;
      background-color: #eee;
      &:last-child {
        border-right: 1px solid #DCDFE6;
      }
      &--xls-col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        text-align: left;
        background-color: #fff;
        :deep(.el-select) {
          flex: 0 0 230px;
          .el-input__inner {
            border: none;
          }
        }
        .el-icon-right {
          flex: 0 0 auto;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
